import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
// import marked from "marked"

//faq accordion
// import { Accordion, Card } from "react-bootstrap"

const DataPartnership = () => (
  <Layout>
    <SEO
      title="Data Partnership"
      description="JBL Sourcing is a provider of full-service recruitment, retention, and other consultative solutions"
    />
    <div
      className="page-headline-datapart"
      
    >
      <div className="container">
        <div className="section-heading text-center">
          <h6 className="font-weight-bold text-uppercase text-white flair">
            Services
          </h6>
          <h1 className="text-white">Data Partnership</h1>
        </div>
      </div>
    </div>
    <div className="page-content">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-9">
            <div className="mb-5">
              <h2 className="text-center p-0 mt-3 mb-2">
                Integrated firm within Med-Tech, Biopharmaceuticals and Life
                Science
              </h2>
              <div className="heading-line2 mx-auto" />
            </div>
            <p>
              As an integrated firm within Med-Tech, Biopharmaceuticals and Life
              Sciences, we have access to data and information that could be
              valuable to your business or your career. Market disruption has
              created uncertainty for organizations; and as a consequence, new
              business models are emerging that combine the expertise of
              partners across the healthcare continuum. Value, access, and cost
              are key drivers. For leaders and for their advisors, understanding
              each segment of healthcare and keeping informed will be critical.
            </p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default DataPartnership
